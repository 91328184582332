import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { decryptFromStoreLoc } from '../helper/localStore';

const BusinessSidebar = () => {
    const { pathname } = useLocation()
    const [activeLink, setActiveLink] = useState(pathname);

    // const handleLinkClick = (to) => {
    //     setActiveLink(to);
    // };

    useEffect(() => {
        const maxLength = 12; // Set the character limit

        const scrollingTextElements = document.querySelectorAll('.scrolling-text');

        scrollingTextElements.forEach((element) => {
            const text = element.textContent;
            if (text.length > maxLength) {
                // Calculate animation duration based on text length (adjust speed if needed)
                const animationDuration = (text.length / maxLength) * 5 + 's';

                // Apply CSS styles for scrolling effect
                element.style.animation = `scroll ${animationDuration} linear infinite`;
                element.style.paddingLeft = '20px'; // Adjust this value according to your needs
            }
        });
    }, []);

    const handleLogout = () => {
        //window.location.reload()
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = "/";
    };

    const [user, setUser] = useState({});

    useEffect(() => {
        const decryptedInfoResult = decryptFromStoreLoc('user');
        setUser(decryptedInfoResult)
    }, []);



    return (
        <>
            <div className="d-flex justify-content-start justify-content-xl-end responsive-sidebar">
                <ul className="admin-left-side-menu mt-4">

                    {
                        user?.is_main_role === true && user?.role === 2 ?
                            <li>
                                <Link
                                    to="/customer-home"
                                    className={`fw-700 ${activeLink === '/customer-home' ? 'active' : ''}`}
                                >
                                    <img src="./admin-images/png/home.png" alt="" />
                                    <div className="scrolling-text-container">
                                        <div className="scrolling-text">Home</div>
                                    </div>
                                </Link>
                            </li>
                            : user?.role === 11 ?
                                <li>
                                    <Link
                                        to="/business-family"
                                        className={`fw-700 ${activeLink === '/business-family' ? 'active' : ''}`}
                                    >
                                        <img src="./admin-images/png/home.png" alt="" />
                                        <div className="scrolling-text-container">
                                            <div className="scrolling-text">Home</div>
                                        </div>
                                    </Link>
                                </li>
                                :
                                <li>
                                    <Link
                                        to="/business-home"
                                        className={`fw-700 ${activeLink === '/business-home' || activeLink === '/bulk-upload' || activeLink === '/assign-course' ? 'active' : ''}`}
                                    >
                                        <img src="./admin-images/png/home.png" alt="" />
                                        <div className="scrolling-text-container">
                                            <div className="scrolling-text">Home</div>
                                        </div>
                                    </Link>
                                </li>
                    }

                    {user?.role === 8 ? (
                        <li>
                            <Link
                                to="/employees-progress"
                                className={`fw-700 ${activeLink === '/employees-progress' ? 'active' : ''}`}
                            >
                                <img src="./admin-images/png/compliance.png" alt="" />
                                <div className="scrolling-text-container">
                                    <div className="scrolling-text">Compliance</div>
                                </div>
                            </Link>
                        </li>
                    ) : user?.role === 6 ? (
                        <li>
                            <Link
                                to="/employee-report"
                                className={`fw-700 ${activeLink === '/employee-report' ? 'active' : ''}`}
                            >
                                <img src="./admin-images/png/compliance.png" alt="" />
                                <div className="scrolling-text-container">
                                    <div className="scrolling-text">Compliance</div>
                                </div>
                            </Link>
                        </li>
                    ) : (
                        <li>
                            <Link
                                to="/business-compliance"
                                className={`fw-700 ${activeLink === '/business-compliance' || activeLink === '/employee-profile' ? 'active' : ''}`}
                            >
                                <img src="./admin-images/png/compliance.png" alt="" />
                                <div className="scrolling-text-container">
                                    <div className="scrolling-text">Compliance</div>
                                </div>
                            </Link>
                        </li>
                    )}


                  {user?.role === 13 &&  <li>
                        <Link
                            to="/family"
                            className={`${activeLink === '/family' ? 'active' : ''}`}
                        >
                            <img src="./admin-images/png/family.png" alt="" />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Family</div>
                            </div>
                        </Link>
                    </li> }

                    <li>
                        <Link
                            to="/business-progress"
                            className={`fw-700 ${activeLink === '/business-progress' ? 'active' : ''}`}
                        >
                            <img src="./admin-images/png/in-progress.png" alt="" />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Team Progress</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/business-bookmarks"
                            className={`fw-700 ${activeLink === '/business-bookmarks' ? 'active' : ''}`}
                        >
                            <img
                                src="./admin-images/png/bookmark.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Bookmarks</div>
                            </div>
                        </Link>
                    </li>
                    {user.role === 9 || user.role === 12 ?
                        <>
                            <li>
                                <Link
                                    to="/bulk-upload"
                                    className={`fw-700 ${activeLink === '/bulk-upload' ? 'active' : ''}`}
                                >

                                    <img
                                        src="./admin-images/png/file.png"
                                        alt=""
                                    />
                                    <div className="scrolling-text-container">
                                        <div className="scrolling-text">Bulk Upload</div>
                                    </div>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/assign-course"
                                    className={`fw-700 ${activeLink === '/assign-course' ? 'active' : ''}`}
                                >

                                    <img
                                        src="./admin-images/png/assign.png"
                                        alt=""
                                    />
                                    <div className="scrolling-text-container">
                                        <div className="scrolling-text">Assign Course</div>
                                    </div>
                                </Link>
                            </li>
                        </> : ""
                    }
                    {/* <li>
                        <Link
                            to="/business-leaderboard"
                            className={`fw-700 ${activeLink === '/business-leaderboard' ? 'active' : ''}`}
                        >

                            <img
                                src="./admin-images/png/leaderboard.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Leaderboard</div>
                            </div>
                        </Link>
                    </li> */}
                    <p className="fw-700 text-black fs-7 my-3">Learning</p>
                    <li>
                        <Link
                            to="/business-courses"
                            className={`fw-700 ${activeLink === '/business-courses' ? 'active' : ''}`}
                        >
                            <img
                                src="./admin-images/png/class-room.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Courses</div>
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link
                            to="/business-quizzes"
                            className={`fw-700 ${activeLink === '/business-quizzes' ? 'active' : ''}`}
                        >
                            <img src="./admin-images/png/maybe.png" alt="" />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Quizzes</div>
                            </div>
                        </Link>
                    </li> */}
                    <p className="fw-700 text-black fs-7 my-3">Other</p>
                    <li>
                        <Link
                            to="/business-learning-groups"
                            className={`fw-700 ${activeLink === '/business-learning-groups' ? 'active' : ''}`}
                        >
                            <img
                                src="./admin-images/png/google-groups.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Learning Groups</div>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/business-analytics"
                            className={`fw-700 ${activeLink === '/business-analytics' ? 'active' : ''}`}
                        >
                            <img
                                src="./admin-images/png/analytic.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Analytics</div>
                            </div>
                        </Link>
                    </li>
                    {/* <li>
                        <Link
                            to="/business-settings"
                            className={`fw-700 ${activeLink === '/business-settings' ? 'active' : ''}`}
                        >                            <img
                                src="./admin-images/png/setting.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Settings</div>
                            </div>
                        </Link>
                    </li> */}
                    <li>
                        <Link
                            className={`fw-700 ${activeLink === '/business-settings' ? '' : ''}`}
                            onClick={() => handleLogout()}
                        >                            <img
                                src="./admin-images/png/shutdown.png"
                                alt=""
                            />
                            <div className="scrolling-text-container">
                                <div className="scrolling-text">Logout</div>
                            </div>
                        </Link>
                    </li>

                </ul>
            </div>
        </>
    )
}

export default BusinessSidebar
