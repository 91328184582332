import React, { useEffect, useRef, useState } from 'react';
import AdminLayout from '../../components/Admin/AdminLayout'
import { Button, Modal, ProgressBar, Table } from 'react-bootstrap';
import StarRating from '../../components/Admin/StarRating';
import CourseManagement from '../../components/Admin/CourseManagement/CourseManagement';
import PopularityModal from '../../components/Admin/CourseManagement/PopularityModal';
import FeedbackScores from '../../components/Admin/CourseManagement/FeedbackScores';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { getServerURL } from '../../helper/envConfig';
import api from '../../helper/api';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import CommonLoader from '../../components/Admin/CommonLoader';


function AdminCourseManagement() {
    const serverURL = getServerURL();
    // const imageURL = getImageBaseUrl();
    const [courses, setCourses] = useState([])
    const [showLoader, setShowLoader] = useState(true);
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalCourse, setTotalCourse] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const [searchFilterText, setSearchFilterText] = useState("")
    const [search, setSearch] = useState('')
    const [shortBy, setShortBy] = useState("")
    const [filterCourseCategory, setFilterCourseCategory] = useState([])
    const [filterPopularity, setFilterPopularity] = useState(null)
    const [filterFeedbackScore, setFilterFeedbackScore] = useState([])
    const [courseCategory, setCourseCategory] = useState([])
    const [activeItems, setActiveItems] = useState({});
    const [deactivateId, setDeactivateId] = useState(null)

    const [showCategoryFilterModel, setShowCategoryFilterMode] = useState(false)
    const [showPopularityModel, setShowPopularityModel] = useState(false)
    const [showFeedbackScoreFilterModel, setShowFeedbackScoreFilterModel] = useState(false)

    const popularityModalRef = useRef(null);
    const feedbackScoreModalRef = useRef(null);
    const courseCategoryModalRef = useRef(null);


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    };


    const getCourseList = async () => {
        try {
            const queryParams = new URLSearchParams({
                page,
                page_size: pageSize,
                ...(searchFilterText ? { search: searchFilterText } : {}),
                ...(shortBy ? { ordering: shortBy } : {}),
                ...(filterCourseCategory.length > 0 ? { course_categories_id_list: filterCourseCategory } : {}),
                ...(filterPopularity ? { from_percentage: filterPopularity.from_percentage } : {}),
                ...(filterPopularity ? { to_percentage: filterPopularity.to_percentage } : {}),
                ...(filterFeedbackScore.length > 0 ? { rating_list: filterFeedbackScore } : {}),
            }).toString();
            const response = await api.getWithToken(`${serverURL}admin-panel/course-management/courses-list?${queryParams}`);
            setCourses(response.data.results.courses);
            setTotalCourse(response.data.total)
            setTotalPages(response.data.total_pages)
            setShowLoader(false)
        } catch (error) {
            setCourses([]);
            console.error(error);
        }
    };


    useEffect(() => {
        getCourseList()
    }, [page])

    useEffect(() => {
        if(page !== 1) {
            setPage(1)
        } else {
            getCourseList()
        }
    }, [searchFilterText, shortBy, filterCourseCategory, filterPopularity, filterFeedbackScore])


    const getCourseCategoryList = async () => {
        try {
            const res = await api.get(`${serverURL}courses/course-categories-list/`)
            setCourseCategory(res.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getCourseCategoryList()
    }, [])


    const handleNextPage = () => {
        if (totalPages > page) {
            setPage(page + 1);
        }
    }

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    // const handleToggle = (key) => {
    //     setActiveItems((prevActiveItems) => ({
    //         ...prevActiveItems,
    //         [key]: !prevActiveItems[key],
    //     }));
    // };

    const handleSearchText = (e) => {
        setSearch(e.target.value)
    }

    const onPressEnterKey = (e) => {
        if (e.key === 'Enter') {
            setSearchFilterText(search.trim())
        }
    }

    const handleShortBy = (field) => {
        if (field === shortBy) {
            setShortBy("-" + field)
        } else {
            setShortBy(field)
        }
    }

    // const handleClickOutside = (event) => {
    //     Object.values(containerRefs).forEach((ref) => {
    //         if (ref.current && !ref.current.contains(event.target)) {
    //             setActiveItems((prevActiveItems) => ({
    //                 ...prevActiveItems,
    //                 [Object.keys(containerRefs).find(key => ref.current === containerRefs[key].current)]: false,
    //             }));
    //         }
    //     });
    // };

    // useEffect(() => {
    //     document.addEventListener('click', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, []);


    const [checkedRows, setCheckedRows] = useState([]);

    // Handle checkbox change
    const handleCheckboxChange = (id) => {
        setCheckedRows((prevCheckedRows) => {
            if (prevCheckedRows.includes(id)) {
                // If already checked, remove it from the list
                return prevCheckedRows.filter((rowId) => rowId !== id);
            } else {
                // If not checked, add it to the list
                return [...prevCheckedRows, id];
            }
        });
    };

    const resetFilter = () => {
        setSearchFilterText("")
        setShortBy("")
        setFilterCourseCategory([])
        setFilterPopularity(null)
        setFilterFeedbackScore([])
        if (popularityModalRef.current) {
            popularityModalRef.current.resetFilter();
        }
        if (feedbackScoreModalRef.current) {
            feedbackScoreModalRef.current.resetFilter();
        }
        if (courseCategoryModalRef.current) {
            courseCategoryModalRef.current.resetFilter();
        }
        setActiveItems({
            feedbackScores: false,
            category: false,
            popularity: false,
        });
    }

    const handleToggle = (key) => {
        if (key === "category") {
            setShowCategoryFilterMode(!showCategoryFilterModel);
            setShowPopularityModel(false);
            setShowFeedbackScoreFilterModel(false);
            setActiveItems({
                category: true,
            });
        } else if (key === "popularity") {
            setShowCategoryFilterMode(false);
            setShowPopularityModel(!showPopularityModel);
            setShowFeedbackScoreFilterModel(false);
            setActiveItems({
                popularity: true,
            });
        } else if (key === "feedbackScores") {
            setShowCategoryFilterMode(false);
            setShowPopularityModel(false);
            setShowFeedbackScoreFilterModel(!showFeedbackScoreFilterModel);
            setActiveItems({
                feedbackScores: true,
            });
        }
    };

    const deActivateCourse = async () => {
        try {
            const res = await api.postWithToken(`${serverURL}admin-panel/course-management/deactivate-course`, { course_id: deactivateId })
            console.log(res)
            toast.success(res.data.message)
            getCourseList()
            setShow(false)
            setDeactivateId(null)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <AdminLayout>
             {showLoader ? (
                <CommonLoader />
            ) : (
                <>
                     <div className='d-md-flex justify-content-between align-items-center'>
                    <h2 className='page-title'>Course Management </h2>
                    <div className='me-1 mt-4 mt-md-0'>
                        <Link to='/admin-course-creation-wizard' className='new-course-btn'> <img src="./admin/round-plus.png" alt="" /> Add a New Course</Link>
                    </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 col-md-6">
                            <div className='table-search-input d-flex align-items-center gap-1'>
                                <img src="./admin/search-dark.png" alt="" />
                                <input type="text" className='form-control border-0' placeholder='Search by title, or others...' onChange={handleSearchText} onKeyDown={onPressEnterKey} />
                            </div>
                        </div>
                    </div>
                    <div className='overflow-auto overflow-y-hidden'>
                        <div className='user-management-scroll'>
                            <div className='table-top-filter-box mt-4 d-inline-flex'>
                                <div className='p-4 border-right-200'>
                                    <img src="./admin/filter.png" width={29} height={29} alt="" />
                                </div>
                                <div className='p-4 border-right-200'>
                                    <p className='mb-0 fs-14 fw-500'>Filter By</p>
                                </div>

                                <div
                                    className='p-4 border-right-200 d-flex align-items-center gap-3 select-user-status-main position-relative'
                                >
                                    <p
                                        className={activeItems.category ? 'mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer' : 'mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer'}
                                        onClick={() => handleToggle('category')}
                                    >
                                        Category
                                        <img className={activeItems.category ? 'd-none' : 'd-block'} src="./admin/bottom-arrow.png" alt="" />
                                        <img className={activeItems.category ? 'd-block' : 'd-none'} src="./admin/bottom-arrow-dark.png" alt="" />
                                    </p>
                                    {showCategoryFilterModel && <div className={activeItems.category ? 'active' : ''}>
                                        <CourseManagement ref={courseCategoryModalRef} courseCategory={courseCategory} setFilterCourseCategory={setFilterCourseCategory} handleToggle={handleToggle} />
                                    </div>}
                                </div>
                                <div
                                    className='p-4 border-right-200 d-flex align-items-center gap-3 select-user-status-main position-relative'
                                >
                                    <p
                                        className={activeItems.popularity ? 'mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer' : 'mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer'}
                                        onClick={() => handleToggle('popularity')}
                                    >
                                        Popularity
                                        <img className={activeItems.popularity ? 'd-none' : 'd-block'} src="./admin/bottom-arrow.png" alt="" />
                                        <img className={activeItems.popularity ? 'd-block' : 'd-none'} src="./admin/bottom-arrow-dark.png" alt="" />
                                    </p>
                                    {showPopularityModel && <div className={activeItems.popularity ? 'active' : ''}>
                                        <PopularityModal ref={popularityModalRef} setFilterPopularity={setFilterPopularity} handleToggle={handleToggle} />
                                    </div>}
                                </div>

                                <div
                                    className='p-4 border-right-200 d-flex align-items-center gap-3 select-user-status-main position-relative'
                                >
                                    <p
                                        className={activeItems.feedbackScores ? 'mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer' : 'mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer'}
                                        onClick={() => handleToggle('feedbackScores')}
                                    >
                                        Feedback Scores
                                        <img className={activeItems.feedbackScores ? 'd-none' : 'd-block'} src="./admin/bottom-arrow.png" alt="" />
                                        <img className={activeItems.feedbackScores ? 'd-block' : 'd-none'} src="./admin/bottom-arrow-dark.png" alt="" />
                                    </p>
                                    {showFeedbackScoreFilterModel && <div className={activeItems.feedbackScores ? 'active' : ''}>
                                        <FeedbackScores ref={feedbackScoreModalRef} setFilterFeedbackScore={setFilterFeedbackScore} handleToggle={handleToggle} />
                                    </div>}
                                </div>
                                <div className='p-4 d-flex align-items-center gap-3 cursor-pointer' onClick={resetFilter}>
                                    <img src="./admin/reset-filter.png" alt="" />
                                    <p className='mb-0 fs-14 fw-500 reset-filter'>Reset Filter</p>
                                </div>
                            </div>
                            <div className='user-management-table mt-4 overflow-auto'>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className='d-flex align-items-center gap-2 cursor-pointer' onClick={() => handleShortBy("name")}>
                                                    <div className='manage-family-checkbox'>
                                                        <div className="form-check mb-2">
                                                        </div>
                                                    </div>
                                                    <p className='mb-0 d-flex align-items-center gap-2'>course title<img src="./admin/arrows-down-up.png" alt="" /></p>
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer' onClick={() => handleShortBy("category__name")}>
                                                    <p>category</p> <img src="./admin/arrows-down-up.png" alt="" />
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer' onClick={() => handleShortBy("popularity_percentage")}>
                                                    <p>popularity score</p> <img src="./admin/arrows-down-up.png" alt="" />
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center gap-2 pb-1 cursor-pointer' onClick={() => handleShortBy("average_rating")}>
                                                    <p>feedback rating</p> <img src="./admin/arrows-down-up.png" alt="" />
                                                </div>
                                            </th>
                                            <th>
                                                <div className='d-flex align-items-center gap-2 pb-1'>
                                                    <p>Action</p>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {courses && courses.length > 0 && courses.map((item, index) => (
                                            <tr key={index} className={checkedRows.includes(item.id) ? 'active' : ''}>
                                                <td>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <div className='manage-family-checkbox'>
                                                            <div className="form-check mb-2">
                                                            </div>
                                                        </div>
                                                        <div className='d-flex align-items-center gap-3'>
                                                            <img src="./admin/table-image.png" alt="" width={62} height={38} className='rounded-2' />
                                                            <p className='text-light-400 fs-7 fw-500 mb-0'>{item.name}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='px-4'>{item.category}</td>
                                                <td className='px-4'>
                                                    <div className='d-flex align-items-center gap-3'>
                                                        <p className='text-light-600 mb-0 fs-20 fw-500'>{item.popularity_percentage}%</p>
                                                        <div className='popularity-score-progress-bar'>
                                                            <ProgressBar now={item.popularity_percentage} />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='px-4'><StarRating ratingData={item.average_rating} /></td>
                                                <td className='px-4'>
                                                    <div className='d-flex justify-content-start align-items-center gap-2'>

                                                        {/* <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    Edit
                                                                </Tooltip>
                                                            }
                                                        >

                                                            <div className="tooltip-hover">
                                                                <img src="./admin/edit.png" className='cursor-pointer light' alt="" width={24} height={24} />
                                                                <img src="./admin/dark-edit.png" className='cursor-pointer dark d-none' alt="" width={24} height={24} />
                                                            </div>

                                                        </OverlayTrigger> */}

                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={
                                                                <Tooltip id="tooltip-top">
                                                                    Deactivate
                                                                </Tooltip>
                                                            }
                                                        >

                                                            <div className="tooltip-hover" onClick={() => {
                                                                handleShow()
                                                                setDeactivateId(item.course_id)
                                                            }
                                                            }>
                                                                <img src="./admin/delete.png" className='cursor-pointer light' alt="" width={24} height={24} />
                                                                <img src="./admin/dark-delete.png" className='cursor-pointer dark d-none' alt="" width={24} height={24} />
                                                            </div>
                                                        </OverlayTrigger>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className='admin-table-pagination'>
                                <p>Showing {(page - 1) * pageSize + 1}-{Math.min(page * pageSize, totalCourse)} of {totalCourse}</p>
                                <div className='admin-table-pagination-arrows'>
                                    <div className='pagination-arrows-box' onClick={handlePrevPage}>
                                        <img src="./admin/pagination-left-arrow.png" alt="" />
                                    </div>
                                    <div className='pagination-arrows-box'>
                                        <img src="./admin/pagination-right-arrow.png" alt="" onClick={handleNextPage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
           

            <Modal show={show} centered size='md' className='m-dark-delete-box-main' onHide={handleClose}>
                <Modal.Header closeButton className='border-0 pb-0'>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-start gap-3">
                        <div className='m-dark-delete-box d-flex justify-content-center align-items-center'>
                            <img src="./admin/dark-delete-2.png" width={30} height={30} alt="" />
                        </div>
                        <div>
                            <h4>Are you sure you want to deactivate this course?</h4>
                            <p>This course will be deactivated.</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0 pt-0 d-block'>
                    <div className='d-flex align-items-center gap-3'>
                        <Button variant="secondary" onClick={() => {
                            handleClose()
                            setDeactivateId(null)
                        }}>
                            No, cancel
                        </Button>
                        <Button variant="primary" onClick={deActivateCourse}>
                            Yes, confirm
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

        </AdminLayout>
    )
}

export default AdminCourseManagement