import { React, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import api from '../helper/api'
import { getServerURL } from '../helper/envConfig'
import { decryptFromStoreLoc, encryptAndStoreLoc } from '../helper/localStore'
// import { validate } from './RegisterSchema';
import { decryptFromStore, encryptAndStore } from '../helper/sessionStore'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'; // Import useHistory for redirection

const Otp = () => {

    const [values, setValues] = useState();
    const [result, setResult] = useState();
    const serverURL = getServerURL();
    const history = useNavigate(); // Get the history object for redirection
    const [role, setRole] = useState();
    const [otpState, setOtpState] = useState('');

    useEffect(() => {
        // Retrieve and decrypt stored information
        const decryptedInfo = decryptFromStoreLoc('user');
        setValues(decryptedInfo)
        const decryptedInfoResult = decryptFromStoreLoc('result');
        setResult(decryptedInfoResult)
        // Retrieve and decrypt stored information
        const Role = decryptFromStore('role');
        setRole(Role);
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();

        const otpValues = Object.values(otpState).filter(value => value !== '');
        if (otpValues.length === 6) {
            const otp = otpValues.join('');

            try {

                var response = await api.post(`${serverURL}users/otp-verification/`, {
                    "user_id": values?.user_id,
                    "otp": otp
                });

                if(response.data?.data?.is_one_time_password) {
                    encryptAndStoreLoc("user",response.data.data)
                    history("/change-password")
                    return;
                }

                if(response.data?.data?.is_associated_with_other && !response.data?.data?.is_associated && response.data?.data.role === 11) {
                    encryptAndStoreLoc("userId",response.data.data)
                } else {
                    localStorage.setItem("token", response?.data?.token.access);
                    encryptAndStoreLoc("user", response.data.data);
                }

                if (result?.result_id) {
                    var response2 = await api.postWithToken(`${serverURL}quizzes/send-certificate/`, {
                        "user_id": values?.user_id,
                        "result_id": result?.result_id
                    });
                    toast.success(response2?.data?.message);
                } else {
                    toast.success(response?.data?.message);
                }


                localStorage.removeItem('result');
                localStorage.removeItem('quiz');
                sessionStorage.clear();

                if (response.data?.data?.is_associated_with_other) {

                    if (response.data?.data?.is_associated) {
                        if (response.data?.data?.main_role && response.data?.data.main_role !== undefined && response.data?.is_main_role) {
                            if (response.data?.data?.main_role == 1) {
                                history("/business-home")
                            } else if (response.data?.data?.main_role == 2) {
                                history("/customer-home")
                            }else if (response.data?.data?.main_role == 7){
                                history("/business-family")
                            }
                        } else {

                            if (response.data?.data?.role == 1 || response.data?.data?.role == 10 || response.data?.data?.role == 12 || response.data?.data?.role == 8 || response.data?.data?.role == 9) {
                                history("/business-home")
                            } else if (response.data?.data?.role == 2) {
                                history("/customer-home")
                            } else if (response.data?.data?.role == 6 || response.data?.data?.role == 11 || response.data?.data?.role == 13 ) {
                                history("/business-family")
                            }

                        }

                    } else {
                        if(response.data?.data.role === 11) {
                            localStorage.setItem("kidsToken", response?.data?.token.access);
                            history("/kids-age-confirm")
                            return
                        } else {
                            history("/update-associated-code")
                        }
                    }

                } else {

                    if (response.data?.data?.main_role && response.data?.data.main_role !== undefined) {
                        if (response.data?.data?.main_role == 1) {
                            history("/business-home")
                        } else if (response.data?.data?.main_role == 2) {
                            history("/customer-home")
                        }else if (response.data?.data?.main_role == 7){
                            history("/business-family")
                        }
                    } else {

                        if (response.data?.data?.role == 1 || response.data?.data?.role == 10 || response.data?.data?.role == 12 || response.data?.data?.role == 8 || response.data?.data?.role == 9) {
                            history("/business-home")
                        } else if (response.data?.data?.role == 2) {
                            history("/customer-home")
                        } else if (response.data?.data?.role == 6 || response.data?.data?.role == 11 || response.data?.data?.role == 13 ) {
                            history("/business-family")
                        }

                    }
                }

            } catch (error) {
                console.log(error);
                toast.error(error.response?.data?.message);
            }

        } else {
            toast.error("Please fill all the OTP fields.");
        }
    };


    const otpResend = async (e) => {
        e.preventDefault();
        try {
            // Assuming `api` and `serverURL` are defined and configured correctly
            const response = await api.post(`${serverURL}users/re-send-otp/`, {
                "user_id": values?.user_id,
            });

            // Handle response here, for example:
            toast.success(response.data.message);
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }

    };


    const otpHandleChange = (e) => {
        const { name, value } = e.target;
        if (/^[0-9]$/i.test(value) || value === '') {
            setOtpState((state) => ({ ...state, [name]: value }));

            // Move to next input if value is a number
            if (value !== '') {
                const nextInput = e.target.form.elements[e.target.tabIndex];
                if (nextInput) {
                    nextInput.focus();
                }
            }
        }
    };

    const inputfocus = (e) => {
        if (e.key === "Delete" || e.key === "Backspace") {
            const currentInputIndex = e.target.tabIndex - 1;
            if (currentInputIndex > 0) {
                const prevInput = e.target.form.elements[currentInputIndex - 1];
                if (prevInput && e.target.value === '') {
                    prevInput.focus();
                }
            }
        }
    };


    return (
        <>
            <Header />
            <section>
                <div className="container-fluid">
                    <div className="sign-up-main">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-md-6 position-relative bg-primary d-flex justify-content-center align-items-center sign-up-col vh-md-100">
                                <img
                                    src="./login/individual-user-vector-1.png"
                                    className="sing-vector-1"
                                    alt=""
                                />
                                <img
                                    src="./login/woman-home.png"
                                    className="project-team"
                                    alt=""
                                />
                                <img
                                    src="./login/individual-user-vector-2.png"
                                    className="sing-vector-2"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 position-relative d-flex justify-content-center align-items-start align-items-md-center sign-up-col sign-up-col-sm vh-md-100">
                                <div className="col-12 col-md-12 col-lg-10 col-xl-9">
                                    <form className="row g-2 form-main" style={{ color: "#002FA7", fontFamily: 'noto sans' }} onSubmit={handleSubmit}>
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-center mb-1">
                                                <span className="fw-700" style={{ fontSize: "35.96px" }}> Enter OTP</span>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <p className="fw-500 text-center" style={{ fontSize: "15px", fontFamily: "noto-sans-semibold" }}>
                                                    Enter the received OTP to ensure the<br />security of your account </p>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <div className="otpContainer py-1 mt-2">
                                                    <input
                                                        name="otp1"
                                                        type="text"
                                                        autoComplete="off"
                                                        className={`otpInput ${otpState?.otp1 ? "otpFillInput" : "otpBlankInput"}`}
                                                        value={otpState?.otp1 || ''}
                                                        //   onKeyPress={keyPressed}
                                                        onChange={e => otpHandleChange(e)}
                                                        tabIndex="1" maxLength="1"
                                                        onKeyUp={e => inputfocus(e)}
                                                    />
                                                    <input
                                                        name="otp2"
                                                        type="text"
                                                        autoComplete="off"
                                                        className={`otpInput ${otpState?.otp2 ? "otpFillInput" : "otpBlankInput"}`}
                                                        value={otpState?.otp2 || ''}
                                                        onChange={e => otpHandleChange(e)}
                                                        tabIndex="2" maxLength="1"
                                                        onKeyUp={e => inputfocus(e)}
                                                    />
                                                    <input
                                                        name="otp3"
                                                        type="text"
                                                        autoComplete="off"
                                                        className={`otpInput ${otpState?.otp3 ? "otpFillInput" : "otpBlankInput"}`}
                                                        value={otpState?.otp3 || ''}
                                                        onChange={e => otpHandleChange(e)}
                                                        tabIndex="3" maxLength="1"
                                                        onKeyUp={e => inputfocus(e)}
                                                    />
                                                    <input
                                                        name="otp4"
                                                        type="text"
                                                        autoComplete="off"
                                                        className={`otpInput ${otpState?.otp4 ? "otpFillInput" : "otpBlankInput"}`}
                                                        value={otpState?.otp4 || ''}
                                                        onChange={e => otpHandleChange(e)}
                                                        tabIndex="4" maxLength="1"
                                                        onKeyUp={e => inputfocus(e)}
                                                    />

                                                    <input
                                                        name="otp5"
                                                        type="text"
                                                        autoComplete="off"
                                                        className={`otpInput ${otpState?.otp5 ? "otpFillInput" : "otpBlankInput"}`}
                                                        value={otpState?.otp5 || ''}
                                                        onChange={e => otpHandleChange(e)}
                                                        tabIndex="5" maxLength="1"
                                                        onKeyUp={e => inputfocus(e)}
                                                    />

                                                    <input
                                                        name="otp6"
                                                        type="text"
                                                        autoComplete="off"
                                                        className={`otpInput ${otpState?.otp6 ? "otpFillInput" : "otpBlankInput"}`}
                                                        value={otpState?.otp6 || ''}
                                                        onChange={e => otpHandleChange(e)}
                                                        tabIndex="6" maxLength="1"
                                                        onKeyUp={e => inputfocus(e)}
                                                    />
                                                </div>
                                            </div>

                                            <p className='account-here pt-4 text-center'
                                                style={{ fontFamily: "noto-sans-semibold", fontSize: "15.38px" }}>Haven't received the code?
                                                <Link onClick={otpResend} className='text-decoration-none fw-700' style={{ color: '#002FA7', fontFamily: "noto-sans-bold" }}>    Send it again.</Link>
                                            </p>
                                            <div className="d-flex justify-content-center">
                                                <button type="submit" className='text-white btn-secondary border-0 mt-1 my-3 otpVerifybutton'>Verify</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Otp