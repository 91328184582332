/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/Admin/AdminLayout";
import DatePicker from "../../components/DatePicker";
import { Table } from "react-bootstrap";
import SendCommunication from "../../components/Admin/UserManagement/SendCommunication";
import SelectUserStatusModal from "../../components/Admin/UserManagement/SelectUserStatusModal";
import SelectRoleModal from "../../components/Admin/UserManagement/SelectRoleModal";
import ExportDataModal from "../../components/Admin/UserManagement/ExportDataModal";
import UpdatePermissions from "../../components/Admin/UserManagement/UpdatePermissions";
import { getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../components/Admin/CommonLoader";

function AdminUserManagement() {
    const navigate = useNavigate();
    const serverURL = getServerURL();
    const [showLoader, setShowLoader] = useState(true);
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [roles, setRoles] = useState([]);
    const [search, setSearch] = useState("");
    const [userStatusFilter, setUserStatusFilter] = useState("");
    const [lastActiveFilterDate, setLastActiveFilterDate] = useState();
    const [filteredDate, setFilteredDate] = useState();
    const [searchFilterText, setSearchFilterText] = useState("");
    const [filterRoles, setFilterRoles] = useState(null);
    const [shortBy, setShortBy] = useState("");
    const [showRegFilterModel, setShowRegFilterModel] = useState(false);
    const [showUserStatusModel, setShowUserStatusFilterModel] = useState(false);
    const [showUserRoleFilterModel, setShowUserRoleFilterModel] =
        useState(false);
    const [showLastActiveFilterModel, setShowLastActiveFilterModel] =
        useState(false);
    const [activeItems, setActiveItems] = useState({});
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allSelected, setAllSelected] = useState(false);

    const getUserList = async () => {
        try {
            const queryParams = new URLSearchParams({
                page,
                page_size: pageSize,
                ...(filteredDate
                    ? {
                          form_date: moment(filteredDate.startDate).format(
                              "YYYY-MM-DD"
                          ),
                          to_date: moment(filteredDate.endDate).format(
                              "YYYY-MM-DD"
                          ),
                      }
                    : {}),
                ...(lastActiveFilterDate
                    ? {
                          last_active_form_date: moment(
                              lastActiveFilterDate.startDate
                          ).format("YYYY-MM-DD"),
                          last_active_to_date: moment(
                              lastActiveFilterDate.endDate
                          ).format("YYYY-MM-DD"),
                      }
                    : {}),
                ...(searchFilterText ? { search: searchFilterText } : {}),
                ...(shortBy ? { ordering: shortBy } : {}),
                ...(filterRoles ? { roles: filterRoles } : {}),
                ...(userStatusFilter ? { status: userStatusFilter } : {}),
            }).toString();
            const response = await api.getWithToken(
                `${serverURL}admin-panel/user-management/users-list?${queryParams}`
            );
            setUsers(response.data.results.users);
            setTotalUsers(response.data.total);
            setTotalPages(response.data.total_pages);
            setShowLoader(false);
        } catch (error) {
            setUsers([]);
            console.error(error);
        }
    };

    useEffect(() => {
        getUserList();
    }, [page]);

    useEffect(() => {
        if (page !== 1) {
            setPage(1);
        } else {
            getUserList();
        }
    }, [
        filteredDate?.startDate,
        filteredDate?.endDate,
        lastActiveFilterDate?.startDate,
        lastActiveFilterDate?.endDate,
        searchFilterText,
        shortBy,
        filterRoles,
        userStatusFilter,
    ]);

    const getUserRoles = async () => {
        try {
            const response = await api.getWithToken(
                `${serverURL}admin-panel/user-management/roles-list`
            );
            setRoles(response.data.data);
        } catch (error) {
            setRoles([]);
            console.error(error);
        }
    };

    useEffect(() => {
        getUserRoles();
    }, []);

    const handleToggle = (key) => {
        if (key === "registration") {
            setShowRegFilterModel(!showRegFilterModel);
            setShowLastActiveFilterModel(false);
            setShowUserStatusFilterModel(false);
            setShowUserRoleFilterModel(false);

            setActiveItems({
                registration: true,
            });
        } else if (key === "userStatus") {
            setShowUserStatusFilterModel(!showUserStatusModel);
            setShowRegFilterModel(false);
            setShowUserRoleFilterModel(false);
            setShowLastActiveFilterModel(false);
            setActiveItems({
                userStatus: true,
            });
        } else if (key === "userRole") {
            setShowUserRoleFilterModel(!showUserRoleFilterModel);
            setShowRegFilterModel(false);
            setShowUserStatusFilterModel(false);
            setShowLastActiveFilterModel(false);
            setActiveItems({
                userRole: true,
            });
        } else if (key === "LastActive") {
            setShowLastActiveFilterModel(!showLastActiveFilterModel);
            setShowUserStatusFilterModel(false);
            setShowRegFilterModel(false);
            setShowUserRoleFilterModel(false);
            setActiveItems({
                LastActive: true,
            });
        }
    };

    // const handleClickOutside = (event) => {
    //     Object.values(containerRefs).forEach((ref) => {
    //         if (ref.current && !ref.current.contains(event.target)) {
    //             setActiveItems((prevActiveItems) => ({
    //                 ...prevActiveItems,
    //                 [Object.keys(containerRefs).find(key => ref.current === containerRefs[key].current)]: false,
    //             }));
    //         }
    //     });
    // };

    // useEffect(() => {
    //     document.addEventListener('click', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('click', handleClickOutside);
    //     };
    // }, []);

    // Handle checkbox change
    const handleCheckboxChange = (id) => {
        setSelectedUsers((prevCheckedRows) => {
            if (prevCheckedRows.includes(id)) {
                // If already checked, remove it from the list
                return prevCheckedRows.filter((rowId) => rowId !== id);
            } else {
                // If not checked, add it to the list
                return [...prevCheckedRows, id];
            }
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedUsers(users.map((user) => user.user_id));
            setAllSelected(true);
        } else {
            setSelectedUsers([]);
            setAllSelected(false);
        }
    };

    const handleSearchText = (e) => {
        setSearch(e.target.value);
    };

    const onPressEnterKey = (e) => {
        if (e.key === "Enter") {
            setSearchFilterText(search.trim());
        }
    };

    const handleNextPage = () => {
        if (totalPages > page) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const handleShortBy = (field) => {
        if (field === shortBy) {
            setShortBy("-" + field);
        } else {
            setShortBy(field);
        }
    };

    const handleResetFilter = () => {
        setPage(1);
        setFilteredDate(null);
        setLastActiveFilterDate(null);
        setUserStatusFilter(null);
        setSearch("");
        setSearchFilterText("");
        setShortBy("");
        setFilterRoles(null);
        setSelectedUsers([]);
        setAllSelected(false);
        setActiveItems({
            LastActive: false,
            userRole: false,
            userStatus: false,
            registration: false,
        });
    };

    const handleRedirectUserProfile = (id) => {
        navigate(`/user-profile-management`, { state: { user_id: id } });
    };

    return (
        <AdminLayout>
            {showLoader ? (
                <CommonLoader />
            ) : (
                <>
                    <h2 className="page-title">User Management </h2>
                    <div className="row mt-5">
                        <div className="col-12 col-md-6">
                            <div className="table-search-input d-flex align-items-center gap-1">
                                <img src="./admin/search-dark.png" alt="" />
                                <input
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="Search by name, or others..."
                                    onChange={handleSearchText}
                                    onKeyDown={onPressEnterKey}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="overflow-auto overflow-y-hidden">
                        <div className="user-management-scroll">
                            <div className="table-top-filter-box mt-4 d-inline-flex">
                                <div className="p-3 border-right-200 d-flex justify-content-center align-items-center">
                                    <img
                                        src="./admin/filter.png"
                                        className="filter-image"
                                        width={29}
                                        height={29}
                                        alt=""
                                    />
                                </div>
                                <div className="p-3 border-right-200 d-flex justify-content-center align-items-center">
                                    <p className="mb-0 fs-14 fw-500">
                                        Filter By
                                    </p>
                                </div>

                                <div className="p-3 border-right-200 d-flex justify-content-center align-items-center filter-items">
                                    <p
                                        className={
                                            activeItems.registration
                                                ? "mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer"
                                                : "mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer"
                                        }
                                        onClick={() =>
                                            handleToggle("registration")
                                        }
                                    >
                                        Registration Date Range
                                        <img
                                            className={
                                                activeItems.registration
                                                    ? "d-none"
                                                    : "d-block"
                                            }
                                            src="./admin/bottom-arrow.png"
                                            alt=""
                                        />
                                        <img
                                            className={
                                                activeItems.registration
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                            src="./admin/bottom-arrow-dark.png"
                                            alt=""
                                        />
                                    </p>
                                    {showRegFilterModel && (
                                        <div
                                            className={
                                                activeItems.registration
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <DatePicker
                                                setFilteredDate={
                                                    setFilteredDate
                                                }
                                                type={"registration"}
                                                handleToggle={handleToggle}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="p-3 border-right-200 d-flex justify-content-center align-items-center d-flex align-items-center gap-3 select-user-status-main position-relative">
                                    <p
                                        className={
                                            activeItems.userStatus
                                                ? "mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer"
                                                : "mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer"
                                        }
                                        onClick={() =>
                                            handleToggle("userStatus")
                                        }
                                    >
                                        User Status
                                        <img
                                            className={
                                                activeItems.userStatus
                                                    ? "d-none"
                                                    : "d-block"
                                            }
                                            src="./admin/bottom-arrow.png"
                                            alt=""
                                        />
                                        <img
                                            className={
                                                activeItems.userStatus
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                            src="./admin/bottom-arrow-dark.png"
                                            alt=""
                                        />
                                    </p>
                                    {showUserStatusModel && (
                                        <div
                                            className={
                                                activeItems.userStatus
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <SelectUserStatusModal
                                                setUserStatusFilter={
                                                    setUserStatusFilter
                                                }
                                                handleToggle={handleToggle}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div className="p-3 border-right-200 d-flex justify-content-center align-items-center d-flex align-items-center gap-3 select-user-status-main position-relative">
                                    <p
                                        className={
                                            activeItems.userRole
                                                ? "mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer"
                                                : "mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer"
                                        }
                                        onClick={() => handleToggle("userRole")}
                                    >
                                        Role
                                        <img
                                            className={
                                                activeItems.userRole
                                                    ? "d-none"
                                                    : "d-block"
                                            }
                                            src="./admin/bottom-arrow.png"
                                            alt=""
                                        />
                                        <img
                                            className={
                                                activeItems.userRole
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                            src="./admin/bottom-arrow-dark.png"
                                            alt=""
                                        />
                                    </p>
                                    {showUserRoleFilterModel && (
                                        <div
                                            className={
                                                activeItems.userRole
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <SelectRoleModal
                                                roles={roles}
                                                setFilterRoles={setFilterRoles}
                                                handleToggle={handleToggle}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="p-3 border-right-200 d-flex justify-content-center align-items-center d-flex align-items-center gap-3 filter-items">
                                    <p
                                        className={
                                            activeItems.LastActive
                                                ? "mb-0 fs-14 fw-500 active text-light-200  d-flex align-items-center gap-3 cursor-pointer"
                                                : "mb-0 fs-14 fw-500  d-flex align-items-center gap-3 cursor-pointer"
                                        }
                                        onClick={() =>
                                            handleToggle("LastActive")
                                        }
                                    >
                                        Last Active
                                        <img
                                            className={
                                                activeItems.LastActive
                                                    ? "d-none"
                                                    : "d-block"
                                            }
                                            src="./admin/bottom-arrow.png"
                                            alt=""
                                        />
                                        <img
                                            className={
                                                activeItems.LastActive
                                                    ? "d-block"
                                                    : "d-none"
                                            }
                                            src="./admin/bottom-arrow-dark.png"
                                            alt=""
                                        />
                                    </p>
                                    {showLastActiveFilterModel && (
                                        <div
                                            className={
                                                activeItems.LastActive
                                                    ? "active"
                                                    : ""
                                            }
                                        >
                                            <DatePicker
                                                setFilteredDate={
                                                    setLastActiveFilterDate
                                                }
                                                type={"LastActive"}
                                                handleToggle={handleToggle}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="p-4 d-flex align-items-center gap-3 cursor-pointer"
                                    onClick={handleResetFilter}
                                >
                                    <img
                                        src="./admin/reset-filter.png"
                                        alt=""
                                    />
                                    <p className="mb-0 fs-14 fw-500 reset-filter">
                                        Reset Filter
                                    </p>
                                </div>
                            </div>
                            <div className="user-management-table mt-4 overflow-auto">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>
                                                <div className="d-flex align-items-center gap-2">
                                                    <div className="manage-family-checkbox">
                                                        <div className="form-check mb-2">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={
                                                                    allSelected
                                                                }
                                                                onChange={
                                                                    handleSelectAll
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <p
                                                        className="mb-0 d-flex align-items-center gap-2"
                                                        onClick={() =>
                                                            handleShortBy(
                                                                "user_id"
                                                            )
                                                        }
                                                    >
                                                        ID
                                                        <img
                                                            src="./admin/arrows-down-up.png"
                                                            alt=""
                                                        />
                                                    </p>
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="d-flex align-items-center gap-2 pb-1 cursor-pointer"
                                                    onClick={() =>
                                                        handleShortBy(
                                                            "username"
                                                        )
                                                    }
                                                >
                                                    <p>NAME</p>{" "}
                                                    <img
                                                        src="./admin/arrows-down-up.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="d-flex align-items-center gap-2 pb-1 cursor-pointer"
                                                    onClick={() =>
                                                        handleShortBy("email")
                                                    }
                                                >
                                                    <p>EMAIL</p>{" "}
                                                    <img
                                                        src="./admin/arrows-down-up.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="d-flex align-items-center gap-2 pb-1 cursor-pointer"
                                                    onClick={() =>
                                                        handleShortBy("role")
                                                    }
                                                >
                                                    <p>ROLE</p>{" "}
                                                    <img
                                                        src="./admin/arrows-down-up.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                            <th>
                                                <div
                                                    className="d-flex align-items-center gap-2 pb-1 cursor-pointer"
                                                    onClick={() =>
                                                        handleShortBy(
                                                            "is_active"
                                                        )
                                                    }
                                                >
                                                    <p>STATUS</p>{" "}
                                                    <img
                                                        src="./admin/arrows-down-up.png"
                                                        alt=""
                                                    />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users &&
                                            users.length > 0 &&
                                            users?.map((item, index) => (
                                                <tr
                                                    key={index}
                                                    className={
                                                        selectedUsers.includes(
                                                            item.user_id
                                                        )
                                                            ? "active cursor-pointer"
                                                            : "cursor-pointer"
                                                    }
                                                    onClick={() => {
                                                        handleRedirectUserProfile(
                                                            item.user_id
                                                        );
                                                    }}
                                                >
                                                    <td>
                                                        <div className="d-flex align-items-center gap-2">
                                                            <div className="manage-family-checkbox">
                                                                <div className="form-check mb-2">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        defaultValue=""
                                                                        checked={selectedUsers.includes(
                                                                            item.user_id
                                                                        )}
                                                                        onClick={(
                                                                            e
                                                                        ) =>
                                                                            e.stopPropagation()
                                                                        }
                                                                        onChange={() => {
                                                                            handleCheckboxChange(
                                                                                item.user_id
                                                                            );
                                                                        }}
                                                                        id={`checkbox-${item.user_id}`}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {item.user_id}
                                                        </div>
                                                    </td>
                                                    <td className="px-2">
                                                        {item.username}
                                                    </td>
                                                    <td className="px-2">
                                                        {item.email}
                                                    </td>
                                                    <td className="px-2">
                                                        {item.role}
                                                    </td>
                                                    <td className="px-2">
                                                        <button
                                                            className={`${
                                                                item.status ===
                                                                "Active"
                                                                    ? "status-active"
                                                                    : item.status ===
                                                                      "Inactive"
                                                                    ? "status-pending"
                                                                    : "status-suspended"
                                                            } status-button`}
                                                        >
                                                            {item.status}
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="admin-table-pagination">
                                <p>
                                    Showing {(page - 1) * pageSize + 1}-
                                    {Math.min(page * pageSize, totalUsers)} of{" "}
                                    {totalUsers}
                                </p>
                                <div className="admin-table-pagination-arrows">
                                    <div
                                        className="pagination-arrows-box"
                                        onClick={handlePrevPage}
                                    >
                                        <img
                                            src="./admin/pagination-left-arrow.png"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="pagination-arrows-box"
                                        onClick={handleNextPage}
                                    >
                                        <img
                                            src="./admin/pagination-right-arrow.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {selectedUsers && selectedUsers.length > 0 && (
                        <div className="table-bottom-bottons mt-3">
                            {/* <div key={item.id} className={selectedUsers.includes(item.id) ? 'd-block' : 'd-none'}> */}
                            <div className="d-flex align-items-center gap-3">
                                <SendCommunication user_ids={selectedUsers} />
                                <ExportDataModal user_ids={selectedUsers} />
                                <UpdatePermissions
                                    user_ids={selectedUsers}
                                    roles={roles}
                                    getUserList={getUserList}
                                />
                            </div>
                            {/* </div> */}
                        </div>
                    )}
                </>
            )}
        </AdminLayout>
    );
}

export default AdminUserManagement;
